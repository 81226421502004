import { useState } from "preact/hooks";
import classNames from "classnames";
import "./popup.scss";
import "./toggle.scss";

const sections = [
  {
    title: "Site Vendors",
    cookies: [
      "California Department of Corrections",
      "Suckless AdNet",
      "Robinhood Trade-Track SDK",
      "DocuSign AdNow",
      "The Onion Ad Network",
      "Citadel PreTrade",
      "Comcast AdsEverywhere",
      "Comcast AdHole SDK",
      "Juul Kids Network",
    ],
  },
  {
    title: "Features",
    cookies: [
      "Who am I? by The Amnesia Project",
      "X11 SDK",
      "Google Stabber",
      "Coinbase Assimilate",
      "Figuratively the Coronavirus",
      "ACLU De-Anonymizer",
      "DARPA Not Skynet SDK",
    ],
  },
  {
    title: "Meta",
    cookies: [
      "Facebook Exploit-Tech SDK",
      "Oculus GoodFun SDK",
      "Meta Smile",
      "Meta GoodHelp",
      "Meta Unite",
      "Meta HappyAds SDK",
      "Meta YaySEO",
      "Instagram KidWatcher SDK",
      "Instagram GoodFamily SDK",
      "Instagram WordScan",
      "Giphy Now! SDK",
    ],
  },
  {
    title: "G. Tinnin Foundation",
    cookies: [
      "G. Tinnin AdLife",
      "G. Tinnin FilterBar",
      "G. Tinnin Sticker SDK",
      "G. Tinnin StickerTracker SDK",
    ],
  },
  {
    title: "Strictly Necessary",
    disableOptOut: true,
    cookies: [
      "GDPR Circumvent SDK",
      "FuckAdBlock",
      "FuckFuckAdblock",
      "FuckFuckFuckAdblock",
      "FuckFuckFuckFuckAdblock",
      "FuckFuckFuckFuckFuckAdblock",
      "Netflix",
    ],
  },
];

const COOKIE_DATA_LOCALSTORAGE_KEY = "cookie-selections";
const POPUP_CLOSED_LOCALSTORAGE_KEY = "cookie-popup-closed";

export default () => {
  const [isPopupClosed, setPopupClosed] = useState(() => {
    document.addEventListener("cookie-popup-open", () => {
      setPopupClosed(false);
    });

    return !!localStorage.getItem(POPUP_CLOSED_LOCALSTORAGE_KEY);
  });

  if (isPopupClosed) {
    return null;
  }

  const [activeTab, setActiveTab] = useState(0);

  const [cookieOptOuts, setCookieOptOuts] = useState(() => {
    return JSON.parse(
      localStorage.getItem(COOKIE_DATA_LOCALSTORAGE_KEY) || "{}"
    );
  });

  const activeSection = sections[activeTab];
  if (activeSection === undefined) {
    throw Error("this should be impossible");
  }

  return (
    <div className="cookie-consent">
      <h1 className="cookie-consent-header">Privacy Settings</h1>

      <section className="cookie-consent-message">
        Please manage your cookies choices by switching the consent toggles on
        or off under the Purposes listed below. You can also choose to click.
        <section className="cookie-consent-message-buttons">
          <button
            className="cookie-consent-interact-button"
            onClick={() => setCookieOptOuts({})}
          >
            Accept All
          </button>
          <button className="cookie-consent-interact-button" disabled>
            Reject All
            <span className="cookie-consent-interact-button-tooltip-text">
              Please make appropriate selections below.
            </span>
          </button>
        </section>
      </section>

      <nav>
        <ul className="cookie-consent-nav">
          {sections.map((section, i) => (
            <li
              className={classNames({ active: i === activeTab })}
              onClick={() => setActiveTab(i)}
            >
              {section.title}
            </li>
          ))}
        </ul>
      </nav>

      <section className="cookie-consent-selection-list">
        <ul>
          {activeSection.cookies.map((cookie) => {
            const activeCookieSection = cookieOptOuts[activeSection.title];
            const optIn = !(activeCookieSection && activeCookieSection[cookie]);

            return (
              <li className="cookie-consent-cookie" key={cookie}>
                <div>{cookie}</div>
                <Toggle
                  disabled={!!activeSection.disableOptOut}
                  value={optIn}
                  // TOOD: 5% chance of triggering neighbor on click event
                  onClick={() => {
                    if (optIn) {
                      setCookieOptOuts({
                        ...cookieOptOuts,
                        [activeSection.title]: {
                          ...cookieOptOuts[activeSection.title],
                          [cookie]: true,
                        },
                      });
                    } else {
                      const { [cookie]: _removed, ...sectionSelections } =
                        cookieOptOuts[activeSection.title];

                      setCookieOptOuts({
                        ...cookieOptOuts,
                        [activeSection.title]: sectionSelections,
                      });
                    }
                  }}
                />
              </li>
            );
          })}
        </ul>
      </section>

      <section className="cookie-consent-footer">
        You can change the above settings for this browser at any time by
        clearing your cookies and reloading the page.
        <section className="cookie-consent-footer-buttons">
          <button
            className="cookie-consent-interact-button cookie-consent-interact-button-light"
            onClick={() => {
              localStorage.removeItem(COOKIE_DATA_LOCALSTORAGE_KEY);
              localStorage.setItem(POPUP_CLOSED_LOCALSTORAGE_KEY, "true");
              setPopupClosed(true);
            }}
          >
            Accept All and Close
          </button>
          <button
            className="cookie-consent-interact-button cookie-consent-interact-button-light"
            onClick={() => {
              localStorage.setItem(
                COOKIE_DATA_LOCALSTORAGE_KEY,
                JSON.stringify(cookieOptOuts)
              );
              localStorage.setItem(POPUP_CLOSED_LOCALSTORAGE_KEY, "true");
              setPopupClosed(true);
            }}
          >
            Save and Close
          </button>
          <button
            className="cookie-consent-interact-button cookie-consent-interact-button-light"
            onClick={() => setPopupClosed(true)}
          >
            Close
          </button>
          <button
            className="cookie-consent-interact-button cookie-consent-interact-button-light"
            onClick={() => setPopupClosed(true)}
          >
            Cancel
          </button>
        </section>
      </section>
    </div>
  );
};

function Toggle(props: {
  value: boolean;
  disabled: boolean;
  onClick: (event: MouseEvent) => void;
}) {
  const statusClass = {
    "toggle-status-on": props.value,
    "toggle-disabled": props.disabled,
  };
  return (
    <div
      className={classNames("toggle", statusClass)}
      onClick={(e) => {
        if (!props.disabled) {
          props.onClick(e);
        }
      }}
    >
      <span className={classNames("toggle-slider", statusClass)}></span>
      <span className={classNames("toggle-text-off", statusClass)}>Off</span>
      <span className={classNames("toggle-text-on", statusClass)}>On</span>
      {props.disabled && (
        <span className="disabled-toggle-tooltip-text">
          This setting cannot be changed.
        </span>
      )}
    </div>
  );
}
